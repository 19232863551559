<template>
  <div class="content-wrapper view-clinic">
    <div class="content">
      <div class="col pt-3 text-right">
        <b-button size="sm" pill @click.prevent="navigateBack">
          <b-icon icon="reply" class="mr-2"></b-icon>ย้อนกลับ
        </b-button>
      </div>
      <div class="container">
        <div class="row ml-lg-5 px-4 justify-content-center">
          <h1 v-if="clinicList.length === 0">
            กรุณาติดต่อ ผู้จัดการ หรือ admin
            เพื่อเพิ่มบัญชีของท่านเข้าไปในรายชื่อ หมอ/พนักงาน ของคลินิก
          </h1>
          <div
            class="col-md-4 col-sm-6 mb-3"
            v-for="valC in clinicList"
            :key="valC.id"
          >
            <div class="card h-100">
              <div class="row text-center">
                <div class="col-12 py-3">
                  <h3>{{ valC.name }}</h3>
                </div>
                <div class="col-12">
                  <Logo :src="viewFile(valC.logo)" :alt="valC.name" />
                </div>
                <div class="col-6 offset-3">
                  <div
                    class="row mt-3"
                    v-for="valB in valC.branches"
                    :key="valB.id"
                  >
                    <button
                      class="btn btn-block btn-outline-primary"
                      @click="selectBatch(valC, valB)"
                    >
                      {{ valB.name }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading v-if="isLoading"></Loading>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loading from "@/components/Loading";
import Logo from "@/components/Logo";

export default {
  name: "ClinicViews",
  components: {
    Loading,
    Logo,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters({
      authorization: "moduleBranch/getAuthorization",
      clinicList: "moduleUser/getClinicList",
      userInfo: "moduleUser/getUserInfo",
      isManager: "moduleUser/isManager",
      isDoctor: "moduleUser/isDoctor",
      isCounter: "moduleUser/isCounter",
      isAssistant: "moduleUser/isAssistant",
      isHeadCounter: "moduleUser/isHeadCounter",
      viewFile: "moduleContent/viewFile",
    }),
  },
  methods: {
    ...mapActions({
      fetchBranchByUrl: "moduleBranch/fetchBranchByUrl",
      fetchClinicList: "moduleUser/fetchClinicList",
      setStateBranchInfo: "moduleUser/setStateBranchInfo",
    }),
    init() {
      this.isLoading = true;
      this.fetchClinicList()
        .then(async () => {
          // Auto-select if only one clinic and one branch exists
          if (
            this.clinicList.length === 1 &&
            this.clinicList[0].branches.length === 1
          ) {
            const clinic = this.clinicList[0];
            const branch = clinic.branches[0];
            await this.selectBatch(clinic, branch);
          }
        })
        .catch(err => {
          this.$router.push("/logout");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    navigateBack() {
      window.history.back();
    },
    async selectBatch(clinic, branch) {
      await this.getBranchInfo(clinic.code, branch.code);
    },
    async getBranchInfo(clinicUrl, branchUrl) {
      if (clinicUrl && branchUrl) {
        this.isLoading = true;
        await this.fetchBranchByUrl({
          clinicUrl: clinicUrl,
          branchUrl: branchUrl,
        })
          .then(res => {
            if (res.status == 200) {
              this.setStateBranchInfo(res.data);
              var authId = this.authorization.authId;
              if (this.isDoctor) {
                this.$router.push("/doctor/dashboard").then(() => {});
              } else if (
                this.isManager ||
                this.isCounter ||
                this.isHeadCounter
              ) {
                this.$router.push("/queuemanage").then(() => {});
              } else if (this.isAssistant) {
                this.$router.push("/doctorsTxQ").then(() => {});
              }
            }
          })
          .catch(err => {
            console.error(err);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style>
</style>